<template>
  <table class="ui-table mb-16">
    <tr>
      <th>
        <div>{{ $t('Managers.user') }}</div>
      </th>
      <th>
        <div>{{ $t('Managers.role') }}</div>
      </th>
      <th>
        <div>{{ $t('Managers.status') }}</div>
      </th>
      <th>
        <div>{{ $t('Managers.telegram') }}</div>
      </th>
      <th>
        <div>{{ $t('Managers.last_activity') }}</div>
      </th>
      <th>
        <div>&nbsp;</div>
      </th>
    </tr>

    <tr
      v-for="staff in staffList"
      :key="staff.id"
      @click="$emit('select', staff)"
      class="hoverable"
      :class="{ hover: staff.id === currentItemId }"
    >
      <td>
        <div class="table-cell nowrap">
          <UiAvatar
            class="user-avatar"
            :src="getAvatarUrl(staff?.user?.photo?.key)"
          ></UiAvatar>
          <span>
            {{ staff.user?.firstName }} {{ staff.user?.middleName }} {{
              staff.user?.lastName
            }}
            <br />
            <span class="email">
              {{ staff.email }}
            </span>
          </span>
        </div>
      </td>

      <td>
        {{ staff.user?.roles.map(e => e.name).join(', ') }}
      </td>

      <td>
        <div>{{ staff.isDeleted ? 'Deactivated' : 'Member' }}</div>
      </td>

      <td>
        <div>@{{ staff.telegramUsername }}</div>
      </td>

      <td>
        <div>{{ $dayjs(staff.updatedAt).format('DD/MM/YYYY') }}</div>
      </td>

      <td>
        <Can do="update" :on="staff">
          <a-button type="link" @click="() => handleEdit(staff.id)">{{
            $t('Managers.edit')
          }}</a-button>
        </Can>
      </td>
    </tr>
  </table>
</template>

<script>
import config from '@/config'
import UiAvatar from '@/components/ui/UiAvatar'
import UiStar from '@/components/ui/UiStar'
import UiStatus from '@/components/ui/UiStatus'
import { subject } from '@casl/ability'

export default {
  name: 'Managers',
  components: { UiStatus, UiStar, UiAvatar },
  props: {
    items: {
      type: Array,
      required: true,
    },
    currentItemId: {},
  },
  emits: ['select'],
  computed: {
    staffList() {
      return this.items.map(i => subject('staff', i))
    }
  },
  methods: {
    getAvatarUrl(key) {
      if (key)
        return `${config.backendUrl}/${key}?token=${localStorage['feathers-jwt']}`
      else return require('@/assets/images/no-avatar.jpg')
    },
    downloadCV(student) {
      const url = `${config.backendUrl}/${student.cv.key}?token=${localStorage['feathers-jwt']}`
      window.open(url)
    },
    handleEdit(staffId) {
      this.$router.push(`/edit-user?tabName=overview&staffId=${staffId}`)
    },
  },
}
</script>

<style scoped>
.email {
  font-size: 14px;
  color: #7d7d7d;
}
</style>
