<template>
  <table class="ui-table mb-16">

    <tr>

      <th>
        <div>{{$t('Students.user')}}</div>
      </th>
      <th>
        <div>{{$t('Students.level')}}</div>
      </th>
      <th>
        <div>{{$t('Students.group')}}</div>
      </th>
      <th>
        <div>{{$t('Students.status')}}</div>
      </th>
      <th>
        <div>{{$t('Students.profession')}}</div>
      </th>
      <th>
        <div>&nbsp;</div>
      </th>
    </tr>

    <tr
        v-for="item in items"
        :key="item.id"
        @click="$emit('select', item)"
        class="hoverable" :class="{hover: item.id === currentItemId}">

      <td>
        <div class="table-cell nowrap">
          <UiAvatar class="user-avatar" :src="getAvatarUrl(item?.user?.photo?.key)"></UiAvatar>
          <span>
            {{ item.firstName }}&nbsp;{{ item.middleName }}&nbsp;{{ item.lastName }}
            <div class="mini-date">{{ $dayjs(item.createdAt).format('DD/MM/YYYY') }}</div>
          </span>
        </div>
      </td>

      <td>
        {{ item.confirmedLanguageLevel || item.initialLanguageLevel }}
      </td>

      <td>
        <div>{{ item.lastStatusName }}</div>
      </td>

      <td>
        <div>@{{ item.telegramUsername }}</div>
      </td>

      <td>
        <div>{{ $dayjs(item.updatedAt).format('DD/MM/YYYY') }}</div>
      </td>

      <td>
        <div>{{$t('Students.edit')}}</div>
      </td>
    </tr>
  </table>
</template>

<script>
import config from "@/config";
import UiAvatar from "@/components/ui/UiAvatar";
import UiStar from "@/components/ui/UiStar";
import UiStatus from "@/components/ui/UiStatus";

export default {
  name: "Students",
  components: {UiStatus, UiStar, UiAvatar},
  props: {
    items: {
      type: Array,
      required: true,
    },
    currentItemId: {}
  },
  emits: ['select'],
  methods: {
    getAvatarUrl(key) {
      if (key) return `${config.backendUrl}/${key}?token=${localStorage['feathers-jwt']}`;
      else return require('@/assets/images/no-avatar.jpg')
    },
    downloadCV(student) {
      const url = `${config.backendUrl}/${student.cv.key}?token=${localStorage['feathers-jwt']}`
      this.$log(url);
      window.open(url);
    },
  }
}
</script>

<style scoped>

</style>