<template>
  <div class="aps-wrap flex-column">
    <div class="aps-head">
      <div class="title1 mb-8">{{ $t('users.users') }}</div>
      <MiniTabs
        :tabs="tabs"
        :tabName="preOpenData.tabName"
        @openTab="openTab"
      />
    </div>
    <StudentTableWithCard
      v-if="currentTab.id === 5411"
      :key="411"
      :options="tabs[0]"
      :preOpen="preOpenData"
      :openable="false"
    />
    <StudentTableWithCard
      v-else-if="currentTab.id === 5412"
      :key="412"
      :options="tabs[1]"
      :preOpen="preOpenData"
      :openable="false"
    />
    <!--    <StudentTableWithCard-->
    <!--        v-else-if="currentTab.id === 445"-->
    <!--        :key="445"-->
    <!--        :options="tabs[2]"-->
    <!--        :preOpen="preOpenData"-->
    <!--    ></StudentTableWithCard>-->
  </div>
  <UndoMessage />
</template>

<script>
import '@/assets/styles/transitions.scss'
import MiniTabs from '@/components/students/card/MiniTabs'
import UndoMessage from '@/components/UndoMessage'
import StudentTableWithCard from '@/components/students/StudentTableWithCard'
import Managers from '@/components/users/Managers'
import Students from '@/components/users/Students'

export default {
  name: 'users',
  components: {
    Managers,
    Students,
    StudentTableWithCard,
    UndoMessage,
    MiniTabs,
  },
  data() {
    return {
      tabs: [
        {
          id: 5411,
          name: 'managers',
          title: 'Managers',
          description: 'Managers',
          cardTabs: ['applicant_info', 'payment_info', 'payment_history'],
          count: 0,
          service: 'staff',
          params: {
            joins: ['user'],
          },
          component: Managers,
        },
        {
          id: 5412,
          name: 'students',
          title: 'Students',
          description: 'Students',
          // statusList: ['interview_booking', 'interview_booked', 'interview_attended', 'interview_missed'],
          cardTabs: ['applicant_info', 'payment_info', 'payment_history'],
          count: 0,
          // service: 'students',
          // schema: 'paymentView',
          component: Students,
        },
        // {name: '|'},
        // {
        //   id: 445,
        //   name: 'invites',
        //   title: 'Invites sent',
        //   description: 'Invites sent',
        //   statusList: [
        //     'review_rejected',
        //     'interview_rejected',
        //     'payment_rejected',
        //   ],
        //   cardTabs: ['applicant_info', 'evaluation', 'payment_info'],
        //   count: 0,
        //   component: Managers,
        // },
      ],
      currentTab: {},
      preOpenData: {},
    }
  },
  methods: {
    openTab(tab) {
      this.currentTab = tab
    },
  },
  created() {
    this.preOpenData = { ...this.$route.query }
  },
  async mounted() {
    // await this.reloadCounters();
  },
}
</script>

<style scoped lang="scss"></style>
